import gql from 'graphql-tag'

export const GET_TALENT_AVAILABILITY = gql`
  query AppointmentCheckListOutput($appointment: CheckAppointmentsInput!) {
    fetchAppointments(appointment: $appointment) {
      success
      appointmentList {
        startTime
        endTime
        booked
        date
        ispast
        host {
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
        }
      }
      error
    }
  }
`

export const FETCH_AVAILABILITY = gql`
  query AvailabilityOutput($authInput: FetchAvailabilityInput!) {
    fetchAvailability(authInput: $authInput) {
      success
      error
      availability {
        date
        startTime
        endTime
        id
        allDays
      }
    }
  }
`

export const CONNECT_GOOGLE_CALENDER = gql`
  mutation GoogleCalendarMutation($googleData: GoogleToken!) {
    connectGoogleCalendar(googleData: $googleData) {
      success
      error
    }
  }
`

export const DISCONNECT_GOOGLE_CALENDER = gql`
  mutation DisconnectGoogleCalendarMutation($userData: TokenInput!) {
    disconnectGoogleCalendar(userData: $userData) {
      success
      error
    }
  }
`

export const CHANGE_CALENDER_SYNC = gql`
  mutation ChangeCalendarSyncMutation($syncData: ChangeSyncInput!) {
    changeCalendarSync(syncData: $syncData) {
      success
      error
    }
  }
`

export const CALENDAR_SYNC_ALL_APPOINTMENTS = gql`
  mutation CalendarSyncAllAppointmentsMutation($authData: CalendarSyncAllInput!) {
    calendarSyncAllAppointments(authData:$authData) {
      success
      error
    }
  }
`

export const CALENDAR_SYNC_ALL_AVAILABILITY = gql`
  mutation CalendarSyncAllAvailabilityMutation($authData: CalendarSyncAllInput!) {
    calendarSyncAllAvailability(authData:$authData) {
      success
      error
    }
  }
`

export const CALENDAR_UNSYNC_ALL_APPOINTMENTS = gql`
  mutation CalendarUnsyncAllAppointmentsMutation($authData: CalendarUnsyncAllInput!) {
    calendarUnsyncAllAppointments(authData:$authData) {
      success
      error
    }
  }
`

export const CALENDAR_UNSYNC_ALL_AVAILABILITY = gql`
  mutation CalendarUnsyncAllAvailabilityMutation($authData: CalendarUnsyncAllInput!) {
    calendarUnsyncAllAvailability(authData:$authData) {
      success
      error
    }
  }
`

export const SYNC_WEEK_DAY_AVAILABILITY = gql`
  mutation SyncWeekDayAvailabilityMutation($calendarData: GoogleToken!) {
    syncWeekdayAvailability(calendarData:$calendarData) {
      success
      error
    }
  }
`
