<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu&&!$device.mobile"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <MobileNavBar v-if="$device.mobile" />
      <!-- <v-menu offset-y class="flex-column " left>
        <template #activator="{ on, attrs }">
          <v-list class="mobile-view">
            <v-list-item class="pa-0 ">
              <v-list-item-avatar class="rounded ma-0 " size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="menu"
                  :src="icon"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/talent/sessions">
            <v-list-item-title>Sessions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/talent/availability">
            <v-list-item-title>Availability</v-list-item-title>
          </v-list-item>
          <v-list-item to="/talent/appointments">
            <v-list-item-title>Appointments</v-list-item-title>
          </v-list-item>
          <v-list-item to="/talent/profile">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-spacer />
      <NotificationButton />
      <v-menu offset-y class="flex-column" left>
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-avatar class="rounded ma-0" size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings">
            <v-list-item-title>{{ $t('account_settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Sidebar -->
    <NavBarList :sidebar.sync="sidebarMenu" />
    <!-- Main Container -->
    <v-container class="py-0 fill-height dashboard-container" fluid>
      <v-dialog
        v-if="deleteDialog"
        v-model="deleteDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="pa-0">
            <v-spacer />
            <v-btn
              icon
              light
              color="black"
              right
              small
              depressed
              class="pl-auto"
              @click="deleteDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{ $t('are_you-sure') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              class="text-h6"
              small
              @click="deleteSession()"
            >
              {{ $t('yes') }}
            </v-btn>
            <v-btn text small class="text-h6" @click="deleteDialog = false">
              {{ $t('no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="dialog"
        v-model="dialog"
        max-width="412"
        class="ml-16 mt-n16"
        width="auto"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2 text-h3">
            {{ $t('create_session') }}
            <v-spacer />
            <v-btn
              icon
              light
              absolute
              color="black"
              right
              @click="resetForm('sessionCreateForm'), (dialog = false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-form ref="sessionCreateForm" lazy-validation>
            <AlertBox :message="error" class="ma-2" />

            <v-col cols="12">
              <p class="subtitle-2 subtitle-2">
                {{ $t('name_session') }}
              </p>
              <v-text-field
                v-model="name"
                :label="$t('session_text_placeholder')"
                filled
                maxlength="50"
                counter
                :rules="rules.session_name"
                @keydown.enter="createSession()"
              />
            </v-col>

            <v-col cols="12">
              <p class="subtitle-2 subtitle-2">
                {{ $t('chose_duration') }}
              </p>
              <v-select
                v-model="length"
                :items="sessionDurations"
                item-text="text"
                item-value="value"
                filled
                :label="$t('selected_time')"
                :rules="rules.required"
                @keydown.enter="createSession()"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="charge"
                data-cy="Charges"
                mask="#########"
                :label="$t('charge')"
                :rules="[
                  v => !!v || $t('session_charge'),
                  v => /^[0-9.]*$/.test(v) || $t('session_charge_num'),
                  v =>
                    v >= length * unitSessionPrice || $t('session_charge_min'),
                ]"
                prefix="$"
                maxlength="10"
                @keydown.enter="createSession()"
              />
            </v-col>
            <v-col md="12" class="text-center">
              <v-btn
                width="204"
                min-width="200"
                min-height="20"
                height="48"
                color="secondary"
                dark
                class="white--text"
                :loading="loading"
                @keydown.enter="createSession()"
                @click="createSession()"
              >
                {{ $t('create_session') }}
              </v-btn>
            </v-col>
            <br />
          </v-form>
        </v-card>
      </v-dialog>
      <v-row class="fill-height">
        <v-col xl="3" lg="4" md="6" sm="12" xs="12" class="text-center">
          <v-row class="grey py-5">
            <v-col md="5" sm="12" xs="12">
              <p class="text-h3 pt-2">
                {{ $t('session_types') }}
              </p>
            </v-col>
            <v-col md="7" sm="12" xs="12">
              <v-btn
                class="text-wrap"
                color="white"
                tile
                elevation="0"
                rounded
                :ripple="false"
                @click=";(dialog = !dialog), (error = null)"
              >
                <v-icon large class="pa-4 ml-n5">
                  mdi-plus
                </v-icon>
                <span class="text-subtitle-1">{{ $t('add_session') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="fill-height">
            <v-col v-if="sessions.length" v-cloak md="12" class="grey px-8">
              <v-card
                v-for="session in sessions"
                :key="session.id"
                class="mx-auto mt-n2 mb-10"
                :to="session.url"
                flat
                active-class="secondary"
              >
                <v-list-item two-line>
                  <v-list-item-avatar
                    tile
                    size="48"
                    color="grey_regular"
                    rounded
                  >
                    <span class="text-h3 text-uppercase blue_spindle--text">{{
                      session.title.charAt(0)
                    }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="text-left">
                    <v-list-item-title class="text-subtitle-1 mb-1 text-wrap">
                      <v-tooltip v-if="session.title.length > 16" top>
                        <template #activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            {{ sessionTitle(session.title) }}
                          </div>
                        </template>
                        <span class="text-wrap"> {{ session.title }}</span>
                      </v-tooltip>
                      <template v-else>
                        {{ sessionTitle(session.title) }}
                      </template>
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-subtitle-2">
                      {{ session.length }} mins./${{
                        session.charge | toFixed(2)
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon class="my-auto">
                    <v-menu left bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="sessionEdit(session)">
                          <v-list-item-title>
                            {{ $t('edit_session') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="
                            ;(deleteDialog = !deleteDialog),
                              (deleteSessionId = session.id)
                          "
                        >
                          <v-list-item-title>
                            {{ $t('delete_session') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-icon>
                </v-list-item>
              </v-card>
            </v-col>
            <v-dialog
              v-if="editDialog"
              v-model="editDialog"
              max-width="412"
              class="ml-16 mt-n16"
              width="auto"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2 text-h3">
                  {{ $t('edit_session') }}
                  <v-spacer />
                  <v-btn
                    icon
                    light
                    absolute
                    color="black"
                    right
                    @click="editDialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-form ref="sessionEditForm" lazy-validation>
                  <AlertBox :message="error" class="ma-2" />

                  <v-col cols="12">
                    <p class="subtitle-2 subtitle-2">
                      {{ $t('name_session') }}
                    </p>
                    <v-text-field
                      v-model="editSession.title"
                      :label="$t('session_text_placeholder')"
                      filled
                      counter
                      maxlength="50"
                      :rules="rules.session_name"
                      @keydown.enter="updateSession()"
                    />
                  </v-col>

                  <v-col cols="12">
                    <p class="subtitle-2 subtitle-2">
                      {{ $t('chose_duration') }}
                    </p>
                    <v-select
                      v-model="editSession.length"
                      :items="sessionDurations"
                      item-text="text"
                      item-value="value"
                      filled
                      :label="$t('selected_time')"
                      :rules="rules.required"
                      @keydown.enter="updateSession()"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editSession.charge"
                      data-cy="Charges"
                      mask="#########"
                      :label="$t('charge')"
                      :rules="[
                        v => !!v || $t('session_charge'),
                        v => /^[0-9.]*$/.test(v) || $t('session_charge_num'),
                        v =>
                          v >= editSession.length * unitSessionPrice ||
                          $t('session_charge_min'),
                      ]"
                      prefix="$"
                      maxlength="50"
                      @keydown.enter="updateSession()"
                    />
                  </v-col>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="white"
                      dark
                      class="black--text d-inline"
                      @click="editDialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="secondary"
                      dark
                      class="white--text d-inline"
                      :loading="loading"
                      @keydown.enter="updateSession()"
                      @click="updateSession()"
                    >
                      {{ $t('update') }}
                    </v-btn>
                  </v-card-actions>
                  <br />
                </v-form>
              </v-card>
            </v-dialog>
            <v-col
              v-if="sessions && !sessions.length"
              v-cloak
              md="12"
              class="grey"
            >
              <div class="title-h2">
                {{ $t('no_sessions') }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="9" lg="8" md="6" sm="12" xs="12" class="px-8 pt-10">
          <v-card flat width="auto">
            <v-row no-gutters class="">
              <v-card class="grey pa-8" flat width="95%">
                <p>
                  <span class="text-h6">{{ $t('google_calendar_header') }}</span>
                </p>
                <p>
                  <img class="pt-2" src="@/assets/images/google-calendar.png" />
                  <span class="pl-2 text-h3">{{ $t('google_calendar') }}</span>
                </p>

                <p class="text-h6 text--opacity-75">
                  {{ $t('sync_appt_dt_tm') }}
                  <br />
                  {{ $t('ths_mk_life_easy') }}
                </p>
                <v-card-actions class="pl-8 d-flex justify-space-between">
                  <v-spacer v-if="!calendarConnected" />
                  <v-btn icon color="green" v-if="calendarConnected">
                    <v-icon left>
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                    Connected
                  </v-btn>
                  <v-btn
                    color="white"
                    class="no-shadow"
                    depressed
                    :ripple="false"
                    :loading="googleLoading"
                    @click="
                      calendarConnected
                        ? disconnectGoogleCalendar()
                        : getGoogleToken()
                    "
                  >
                    {{ !calendarConnected ? $t('cnnect') : $t('dis_cnnect') }}
                  </v-btn>
                </v-card-actions>
                
                <v-row
                  class="justify-space-around preference-settings mt-4"
                  v-if="calendarConnected&&!syncLoading"
                >
                  <v-checkbox
                    v-model="appointmentsCheckbox"
                    label="Appointments"
                    @change="syncAllUpcomingAppointments()"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="availabilityCheckbox"
                    label="Availability"
                    @change="syncAllAvailability()"
                  ></v-checkbox>
                </v-row>
                <v-row
                  class="justify-space-around preference-settings mt-4"
                  v-if="calendarConnected&&syncLoading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
                <AlertBox :message="googleError" class="ma-2" />
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

// component
import NotificationButton from '@/components/generic/NotificationButton.vue'
import MobileNavBar from '@/components/generic/MobileNavBar.vue'
import NavBarList from '@/pages/talent/dashboard/NavBarList.vue'

// utils
import { rules } from '@/utils/validation'
import { sessionDurations } from '@/utils/static'
import i18n from '@/utils/i18n.js'

//graphql
import {
  CREATE_SESSION_TYPE,
  UPDATE_SESSION_TYPE,
  DELETE_SESSION_TYPE,
  GET_SESSIONS_LIST,
} from '@/graphql/sessions'
import { SET_AVAILABILITY, FETCH_AVAILABILITY } from '@/graphql/talent'
import {
  CONNECT_GOOGLE_CALENDER,
  DISCONNECT_GOOGLE_CALENDER,
  CHANGE_CALENDER_SYNC,
  CALENDAR_SYNC_ALL_APPOINTMENTS,
  CALENDAR_SYNC_ALL_AVAILABILITY,
  CALENDAR_UNSYNC_ALL_APPOINTMENTS,
  CALENDAR_UNSYNC_ALL_AVAILABILITY,
} from '@/graphql/calendar'

export default {
  components: {
    NotificationButton,
    MobileNavBar,
    NavBarList,
  },
  data() {
    return {
      sessionDurations,
      sidebarMenu: true,
      toggleMini: false,
      currentDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      rules,
      items: [
        {
          title: 'Dashboard',
          img: '/images/icon/talent/dashboard.png',
          url: '/talent/dashboard',
        },
        {
          title: 'Sessions',
          img: '/images/icon/talent/sessions.png',
          url: '/talent/sessions',
        },
        {
          title: 'Appointments',
          img: '/images/icon/talent/appointments.png',
          url: '/talent/appointments#1',
        },
        // { title: "Help", img: "/images/icon/talent/help.png", url: "/talent/help" },
      ],
      loading: false,
      name: null,
      length: null,
      charge: null,
      availableDate: null,
      dateMenu: false,
      startTime: null,
      endTime: null,
      modal1: false,
      modal2: false,
      allDay: false,
      success: null,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      selectedSession: {},
      error: null,
      sessions: [],
      userInfo: {},
      deleteSessionId: null,
      editSession: {},
      icon: null,
      availability: {
        date: null,
        startTime: null,
        endTime: null,
        allDays: false,
      },
      appointments: [],
      googleLoading: null,
      googleError: null,
      calendarConnected: null,
      appointmentsCheckbox: false,
      availabilityCheckbox: false,
      unitSessionPrice: null,
      syncLoading: false
    }
  },
  computed: {
    ...mapGetters(['profileImage']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
    buttonText() {
      return !this.$vuetify.theme.dark ? 'Go Dark' : 'Go Light'
    },
    sessionTitle() {
      return title => (title.length > 16 ? `${title.substr(0, 16)}...` : title)
    },
  },
  beforeMount() {
    this.icon = '/images/icon/talent/menu.png'
    if(this.$device.mobile) this.sidebarMenu = false;
  },
  created() {
    this.userInfo = this.$store.getters['user']
    this.userInfo.accesstoken = this.$store.getters['jwt']
    this.calendarConnected = this.userInfo.gcalData?.linked
    this.appointmentsCheckbox = this.userInfo.gcalData?.syncAppointments
    this.availabilityCheckbox = this.userInfo.gcalData?.syncAvailability
  },
  async mounted() {
    await this.fetchAvailability()
    await this.getSessionsList()
  },
  methods: {
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    /**
     * Selected Session
     */
    singleSession(session) {
      this.selectedSession = session
    },

    /**
     * Fetch Availability
     */
    async fetchAvailability() {
      const {
        data: { fetchAvailability },
      } = await this.$apollo.query({
        query: FETCH_AVAILABILITY,
        variables: {
          authInput: {
            email: this.userInfo.email,
            accesstoken: this.userInfo.accesstoken,
            tzinfo: this.$defaultTZ,
          },
        },
      })
      if (fetchAvailability.success) {
        this.availability = { ...fetchAvailability.availability }
        this.availability.startTime = this.$moment(
          this.availability.startTime,
          'HH:mm'
        ).format('HH:mm')
        this.availability.endTime = this.$moment(
          this.availability.endTime,
          'HH:mm'
        ).format('HH:mm')
      } else {
        // Error
      }
    },
    /**
     * Create New Session
     */
    async createSession() {
      if (this.$refs.sessionCreateForm.validate()) {
        this.loading = true
        this.error = null
        const { name, length, charge } = this
        try {
          const {
            data: { createSessionType },
          } = await this.$apollo.mutate({
            mutation: CREATE_SESSION_TYPE,
            variables: {
              sessionData: {
                email: this.userInfo.email,
                accesstoken: this.userInfo.accesstoken,
                title: name,
                length: length,
                charge: charge,
              },
            },
          })
          if (createSessionType.success) {
            this.dialog = false
            this.loading = false
            this.$refs.sessionCreateForm.reset()
            await this.getSessionsList()
          } else {
            this.loading = false
            this.error = this.$t(createSessionType.error)
          }
        } catch (e) {
          this.loading = false
          this.error = e
        }
      }
    },

    /**
     * Update Session
     */
    async updateSession() {
      if (this.$refs.sessionEditForm.validate()) {
        this.loading = true
        const { title, length, charge, id } = this.editSession
        try {
          const {
            data: { editSessionType },
          } = await this.$apollo.mutate({
            mutation: UPDATE_SESSION_TYPE,
            variables: {
              sessionData: {
                id: id,
                email: this.userInfo.email,
                accesstoken: this.userInfo.accesstoken,
                title: title,
                length: length,
                charge: charge,
              },
            },
          })
          if (editSessionType.success) {
            this.editDialog = false
            this.loading = false
            await this.getSessionsList()
          } else {
            this.loading = false
            this.error = this.$t(editSessionType.error)
          }
        } catch (e) {
          this.loading = false
          this.error = e
        }
      }
    },

    /**
     * Delete Session
     */
    async deleteSession() {
      if (this.deleteSessionId) {
        try {
          const {
            data: { deleteSessionType },
          } = await this.$apollo.mutate({
            mutation: DELETE_SESSION_TYPE,
            variables: {
              sessionData: {
                id: this.deleteSessionId,
                email: this.userInfo.email,
                accesstoken: this.userInfo.accesstoken,
              },
            },
          })
          if (deleteSessionType.success) {
            this.deleteDialog = false
            this.deleteSessionId = null
            await this.getSessionsList()
          } else {
            this.error = this.$t(deleteSessionType.error)
          }
        } catch (e) {
          this.error = e
        }
      }
    },

    /**
     * Bind data to edit particular session
     */
    sessionEdit(session) {
      this.error = null
      this.editSession = { ...session }
      this.editSession.charge = this.$options.filters.toFixed(
        this.editSession.charge,
        2
      )
      this.editDialog = true
    },

    /**
     * Get sessions list
     */
    async getSessionsList() {
      const {
        data: { fetchSessionTypeList },
      } = await this.$apollo.query({
        query: GET_SESSIONS_LIST,
        variables: {
          email: this.userInfo.email,
        },
        fetchPolicy: 'network-only',
      })
      if (fetchSessionTypeList.success) {
        this.sessions = fetchSessionTypeList.sessionType
        this.unitSessionPrice = parseFloat(
          fetchSessionTypeList.unitSessionPrice
        )
      } else {
        // Error
        this.sessions = []
        this.availability.date = null
        this.availability.startTime = null
        this.availability.endTime = null
        this.availability.allDays = false

        this.$refs.sessionAvailabilityForm.reset()
      }
    },

    /**
     * Reset Form
     */
    resetForm(formName) {
      this.$refs[formName].reset()
    },
    /**
     * All upcoming appointments Sync into Google Calendar
     */

    async syncAllUpcomingAppointments() {
      this.syncLoading = true
      const {
          data: { changeCalendarSync },
        } = await this.$apollo.mutate({
          mutation: CHANGE_CALENDER_SYNC,
          variables: {
            syncData: {
              email: this.userInfo.email,
              accesstoken: this.userInfo.accesstoken,
              syncAppointments: this.appointmentsCheckbox,
              syncAvailability: this.availabilityCheckbox
            },
          },
        })

        if (changeCalendarSync.success) {
          console.log('get all appointments', this.appointmentsCheckbox)
          if (this.appointmentsCheckbox) {
            console.log('Sync all appointments of current host')
            const {
              data: { calendarSyncAllAppointments },
            } = await this.$apollo.mutate({
              mutation: CALENDAR_SYNC_ALL_APPOINTMENTS,
              variables: {
                authData: { 
                  email: this.userInfo.email,
                  accesstoken: this.userInfo.accesstoken,
                  tzinfo: this.$defaultTZ,
                },
              },
            })
            if (calendarSyncAllAppointments.success) {
              console.log(
                'successfully done sync with previously arranged appointments'
              )
            } else {
              console.log('error in sync of previously arranged apointments')
            }
            this.$store.commit("setGcalData", {
              linked: true, 
              syncAppointments: this.appointmentsCheckbox, 
              syncAvailability: this.availabilityCheckbox
            });
            this.syncLoading = false
          } else {
            // unsync all appointments of current host
            console.log('unsync all appointments')
            const {
              data: { calendarUnsyncAllAppointments },
            } = await this.$apollo.mutate({
              mutation: CALENDAR_UNSYNC_ALL_APPOINTMENTS,
              variables: {
                authData: { 
                  email: this.userInfo.email,
                  accesstoken: this.userInfo.accesstoken,
                  tzinfo: this.$defaultTZ,
                },
              },
            })
            if (calendarUnsyncAllAppointments.success) {
              console.log(
                'successfully done unsync with previously arranged appointments'
              )
            } else {
              console.log('error in unsync of previously arranged apointments')
            }
            this.$store.commit("setGcalData", {
              linked: true, 
              syncAppointments: this.appointmentsCheckbox, 
              syncAvailability: this.availabilityCheckbox
            });
            this.syncLoading = false
          }
        }
    },
    /**
     * All availabilities Sync into Google Calendar
     */
    async syncAllAvailability() {
      this.syncLoading = true
      const {
          data: { changeCalendarSync },
        } = await this.$apollo.mutate({
          mutation: CHANGE_CALENDER_SYNC,
          variables: {
            syncData: {
              email: this.userInfo.email,
              accesstoken: this.userInfo.accesstoken,
              syncAppointments: this.appointmentsCheckbox,
              syncAvailability: this.availabilityCheckbox
            },
          },
        })

        if (changeCalendarSync.success) {
          if (this.availabilityCheckbox) {
            console.log('Sync all availability of current host')
            const {
              data: { calendarSyncAllAvailability },
            } = await this.$apollo.mutate({
              mutation: CALENDAR_SYNC_ALL_AVAILABILITY,
              variables: {
                authData: {
                  email: this.userInfo.email,
                  accesstoken: this.userInfo.accesstoken,
                  tzinfo: this.$defaultTZ,
                },
              },
            })
            if (calendarSyncAllAvailability.success) {
              this.$store.commit("setGcalData", {
                linked: true, 
                syncAppointments: this.appointmentsCheckbox, 
                syncAvailability: this.availabilityCheckbox
              });
              this.syncLoading = false
            } else {
              console.log('error in sync of availability')
            }
          } else {
            console.log('unsync all availability')
            const {
              data: { calendarUnsyncAllAvailability },
            } = await this.$apollo.mutate({
              mutation: CALENDAR_UNSYNC_ALL_AVAILABILITY,
              variables: {
                authData: {
                  email: this.userInfo.email,
                  accesstoken: this.userInfo.accesstoken,
                  tzinfo: this.$defaultTZ,
                },
              },
            })
            if (calendarUnsyncAllAvailability.success) {
              this.$store.commit("setGcalData", {
                linked: true, 
                syncAppointments: this.appointmentsCheckbox, 
                syncAvailability: this.availabilityCheckbox
              });
              this.syncLoading = false
            } else {
              console.log('error in unsync of availability')
            }
          }
        }
        
    },
    /**
     * get google calendar
     */
    async getGoogleToken() {
      this.googleLoading = true
      try {
        const authCode = await this.$gAuth.getAuthCode()
        const {
          data: { connectGoogleCalendar },
        } = await this.$apollo.mutate({
          mutation: CONNECT_GOOGLE_CALENDER,
          variables: {
            googleData: {
              email: this.userInfo.email,
              accesstoken: this.userInfo.accesstoken,
              refreshToken: authCode,
              tzinfo: this.$defaultTZ,
            },
          },
        })
        if (connectGoogleCalendar.success) {
          this.$store.commit("setGcalData", {linked: true, syncAppointments: true, syncAvailability: true});
          this.appointmentsCheckbox = true;
          this.availabilityCheckbox = true;
          this.calendarConnected = true
        } else {
          this.googleError = this.$t(connectGoogleCalendar.error)
        }
        this.googleLoading = false
      } catch (error) {
        //on fail do something
        console.log(error)
        this.googleLoading = false
        return null
      }
    },
    /**
     * disconnect google calendar
     */
    async disconnectGoogleCalendar() {
      this.googleLoading = true
      try {
        const {
          data: { disconnectGoogleCalendar },
        } = await this.$apollo.mutate({
          mutation: DISCONNECT_GOOGLE_CALENDER,
          variables: {
            userData: {
              email: this.userInfo.email,
              accesstoken: this.userInfo.accesstoken,
              tzinfo: this.$defaultTZ,
            },
          },
        })

        if (disconnectGoogleCalendar.success) {
          this.$store.commit("setGcalData", {linked: false, syncAppointments: true, syncAvailability: true});
          this.calendarConnected = false
        } else {
          this.googleError = this.$t(disconnectGoogleCalendar.error)
        }
        this.googleLoading = false
      } catch (error) {
        //on fail do something
        console.error(error)
        this.googleLoading = false
        return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-list {
  margin-top: 2px;
  .v-list-item--active {
    border-radius: 4px !important;
    .v-list-item__title {
      color: white;
    }
  }
}
.availability-card.v-sheet.v-card {
  border-color: $blue_spindle;
}
.preference-settings {
  background-color: #e2e2e2;
  height: 66px;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .search-box.v-card.v-sheet.theme--light {
    display: none;
  }
  nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .v-image.v-responsive.elevation-6.theme--light {
    display: block;
  }
}
</style>
